<template>
  <div class="admin-all">
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <h2>{{ $t('systemPage.brandOwners') }}</h2>
          <p>{{ dataAll.boCount || 0 }}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <h2>{{ $t('systemPage.suppliers') }}</h2>
          <p>{{ dataAll.spCount || 0 }}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <h2>{{ $t('systemPage.investors') }}</h2>
          <p>{{ dataAll.ivCount || 0 }}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "admin-all",
  data() {
    return {
      dataAll: {}
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      this.$axios.get('/manage-common/overview', {}).then(result => {
        if (!result.code) {
          _this.dataAll = result.data;
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.admin-all {
  .grid-content {
    padding: 40px 23px 34px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
    h2 {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 30px;
    }
    p {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 30px;
    }
  }
  .bg-purple {
    background-color: #80D3CB;
  }
}
</style>